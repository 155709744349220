// Here you can add other styles
.no-records {
    text-align: center;
    margin: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.no-records p {
    font-size: 16px;
    color: #333;
    margin: 0;
    padding: 5px;
}


.editButton {
    background-color: #572cea;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
  }

  .deleteButton {
    background-color: rgb(200, 84, 84);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
  }